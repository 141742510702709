<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="串接新制設定" @edit="dialog.edit = true" />

    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="是否啟用串接新制" prop="enableLiff">
        <div class="secret-text">
          <span>{{ !config.enableLiff ? '啟用' : '關閉' }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <EditLineNewDialog
      v-if="dialog.edit"
      :config="config"
      @close="dialog.edit = false"
      @refresh="getClientPageConfig"
    />
  </section>
</template>

<script>
import EditLineNewDialog from './EditLineNewDialog.vue'
import { GetClientPageConfig } from '@/api/member/memberCenter'
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'LineNewSettings',
  components: { EditLineNewDialog },
  setup() {
    const { shopId } = useShop()
    const config = ref({})
    const loading = ref(false)
    const dialog = reactive({
      edit: false,
    })
    const getClientPageConfig = async() => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await GetClientPageConfig({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      config.value = res
    }
    onMounted( async() => {
      await getClientPageConfig()
    })
    return {
      config,
      dialog,
      loading,
      getClientPageConfig,
    }
  },
})
</script>

<style lang="scss" scoped>

</style>
