<template>
  <section class="section-block">
    <SectionTitle title="Line Login Channel 設定" @edit="dialog.edit = true" />

    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="Channel ID" prop="channelId">
        <div v-if="config.channelId" class="secret-text inline-flex items-center gap-[8px] cursor-pointer" @click="toLineChannel">
          <p>{{ config.channelId }}</p>
          <SVGIcon icon="common-upRightFromSquare"
            width="16px"
            height="16px"
            fill="var(--gray-60)" />
        </div>
        <span v-else>尚未設定</span>
      </BaseElFormItem>
      <BaseElFormItem label="Channel Secret" prop="channelSecret">
        <div class="secret-text">
          <span>{{ config.channelSecret || '尚未設定' }}</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="Callback URL">
        <span v-if="isEmpty(redirectURL.lines)">尚未填寫</span>
        <div v-else class="flex">
          <div class="flex flex-col">
            <p v-for="item in redirectURL.lines" :key="item">{{ item }}</p>
          </div>
          <img
            src="@/assets/icon/copy.svg"
            class="cursor-pointer"
            @click="copyUrl(redirectURL.lines)"
          >
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="外部瀏覽器不中斷綁定通知" prop="enableLoginBotLink">
        <div class="secret-text">
          <span>{{ config.enableLoginBotLink ? '啟用' : '關閉' }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <EditLineLoginDialog
      v-if="dialog.edit"
      :config="config"
      @close="dialog.edit = false"
      @refresh="getLineLoginConfig"
    />
  </section>
</template>

<script>
import EditLineLoginDialog from './EditLineLoginDialog.vue'
import { GetLineLoginConfig, GetLineLoginConfigRedirectUrl } from '@/api/line'
import copy from 'clipboard-copy'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { get, isEmpty } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'LineLoginSettings',
  components: { EditLineLoginDialog },
  setup() {
    const { shopId } = useShop()
    const config = ref({})
    const dialog = reactive({
      edit: false,
    })
    const redirectURL = reactive({
      lines: null,
    })
    const toLineChannel = () => {
      window.open(`https://developers.line.biz/console/channel/${get(config.value, 'channelSecret')}`)
    }
    const getLineLoginConfig = async () => {
      const [res, err] = await GetLineLoginConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      config.value = res
    }
    const getLineLoginConfigRedirectUrl = async () => {
      const [res, err] = await GetLineLoginConfigRedirectUrl({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      redirectURL.lines = res.redirectUrls
    }
    const copyUrl = (url) => {
      copy(url.join('\n'))
      window.$message.success('已成功複製連結！')
    }

    onMounted( async() => {
      await Promise.allSettled([
        getLineLoginConfig(),
        getLineLoginConfigRedirectUrl(),
      ])
    })

    return {
      config,
      dialog,
      redirectURL,
      copyUrl,
      toLineChannel,
      isEmpty,
    }
  },
})
</script>

<style lang="scss" scoped>

</style>
