<template>
  <section class="section-block">
    <SectionTitle title="Line 綁定設定" hideBtn />

    <BaseElForm label-position="left" label-width="300px">
      <BaseElFormItem label="綁定與加入好友提醒" prop="channelId">
        <BaseElSwitch
          v-model="config.enabledJoinFriend"
          active-text="開啟"
          inactive-text="關閉"
          @change="updateLineBindingConfig"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="config.enabledJoinFriend" label="限制Line綁定會員才可使用系統服務" prop="channelId">
        <BaseElSwitch
          v-model="config.requiredJoinFriend"
          active-text="開啟"
          inactive-text="關閉"
          @change="updateLineBindingConfig"
        />
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script>
import { GetLineBindingConfig, UpdateLineBindingConfig } from '@/api/line'
import { mapGetters } from 'vuex'
export default {
  name: 'LineBindingSetting',
  data: () => ({
    config: { enabledJoinFriend: false, requiredJoinFriend: false },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  async mounted () {
    await this.getLineBindingConfig()
  },
  methods: {
    async getLineBindingConfig () {
      const [res, err] = await GetLineBindingConfig({
        shopId: this.shop,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.config = res
    },
    async updateLineBindingConfig () {
      const [, err] = await UpdateLineBindingConfig({
        shopId: this.shop,
        enabledJoinFriend: this.config.enabledJoinFriend,
        requiredJoinFriend: this.config.requiredJoinFriend,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新成功！')
      await this.getLineBindingConfig()
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
